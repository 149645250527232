import { SearchSelectBox } from '@/ui/components/dashboard/components/search-select-box'

import { FormControl, FormItem, FormMessage } from '../../form'
import AutoFormLabel from '../common/label'
import AutoFormTooltip from '../common/tooltip'
import type { AutoFormInputComponentProps } from '../types'

export default function AutoFormSearchBox({
  label,
  isRequired,
  field,
  fieldConfigItem,
  fieldProps,
}: AutoFormInputComponentProps) {
  return (
    <FormItem className="flex flex-col gap-2">
      <AutoFormLabel label={label} isRequired={isRequired} />
      <FormControl>
        <SearchSelectBox
          selectedOption={field.value}
          onSelectedOptionChanged={field.onChange}
          allOptions={fieldConfigItem.inputProps?.allOptions}
          {...fieldProps}
        />
      </FormControl>
      <AutoFormTooltip fieldConfigItem={fieldConfigItem} />
      <FormMessage />
    </FormItem>
  )
}

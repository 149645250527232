'use client'

import { FancyMultiSelect } from '@/ui/components/dashboard/components/fancy-multi-select'

import { FormControl, FormItem, FormMessage } from '../../form'
import AutoFormLabel from '../common/label'
import AutoFormTooltip from '../common/tooltip'
import { AutoFormInputComponentProps } from '../types'

export default function AutoFormMultiSelect({
  label,
  isRequired,
  field,
  fieldConfigItem,
  fieldProps,
}: AutoFormInputComponentProps) {
  return (
    <FormItem className="flex flex-col gap-2">
      <AutoFormLabel label={label} isRequired={isRequired} />
      <FormControl>
        <FancyMultiSelect
          selected={field.value ? field.value.split('|') : []}
          onSetSelected={(values) => {
            field.onChange(values.join('|'))
          }}
          options={fieldConfigItem.inputProps?.allOptions}
          // placeHolder={field.}
          {...fieldProps}
        />
      </FormControl>
      <AutoFormTooltip fieldConfigItem={fieldConfigItem} />
      <FormMessage />
    </FormItem>
  )
}

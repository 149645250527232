import { DateTimePicker } from '@/ui/components/dashboard/components/date-time-picker'

import { FormControl, FormItem, FormMessage } from '../../form'
import AutoFormLabel from '../common/label'
import AutoFormTooltip from '../common/tooltip'
import type { AutoFormInputComponentProps } from '../types'

export default function AutoFormDate({
  label,
  isRequired,
  field,
  fieldConfigItem,
  fieldProps,
}: AutoFormInputComponentProps) {
  return (
    <FormItem>
      <AutoFormLabel label={label} isRequired={isRequired} />
      <FormControl>
        <DateTimePicker
          date={
            typeof field.value === 'string'
              ? new Date(field.value)
              : field.value
          }
          setDate={field.onChange}
          {...fieldProps}
        />
      </FormControl>
      <AutoFormTooltip fieldConfigItem={fieldConfigItem} />
      <FormMessage />
    </FormItem>
  )
}

import dynamic from 'next/dynamic'

import AutoFormCheckbox from './fields/checkbox'
import AutoFormDate from './fields/date'
import AutoFormDateTime from './fields/date-time'
import AutoFormEnum from './fields/enum'
import AutoFormFile from './fields/file'
import AutoFormInput from './fields/input'
import AutoFormMultiSelect from './fields/multi-select'
import AutoFormNumber from './fields/number'
import AutoFormRadioGroup from './fields/radio-group'
import AutoFormSearchBox from './fields/search-select-box'
import AutoFormSwitch from './fields/switch'
import AutoFormTextarea from './fields/textarea'
import AutoFormWarning from './fields/warning'

// import AutoFormMarkdown from './fields/markdown'
const AutoFormMarkdown = dynamic(() => import('./fields/markdown'), {
  ssr: false,
})

export const INPUT_COMPONENTS = {
  checkbox: AutoFormCheckbox,
  date: AutoFormDate,
  select: AutoFormEnum,
  'multi-select': AutoFormMultiSelect,
  radio: AutoFormRadioGroup,
  switch: AutoFormSwitch,
  textarea: AutoFormTextarea,
  number: AutoFormNumber,
  file: AutoFormFile,
  fallback: AutoFormInput,
  hidden: AutoFormInput,
  'date-time': AutoFormDateTime,
  'search-select-box': AutoFormSearchBox,
  warning: AutoFormWarning,
  markdown: AutoFormMarkdown,
}

/**
 * Define handlers for specific Zod types.
 * You can expand this object to support more types.
 */
export const DEFAULT_ZOD_HANDLERS: Record<
  string,
  keyof typeof INPUT_COMPONENTS
> = {
  ZodBoolean: 'checkbox',
  ZodDate: 'date',
  ZodEnum: 'select',
  ZodNativeEnum: 'select',
  ZodNumber: 'number',
}

'use client'

import * as React from 'react'
import { Button } from '@/ui/components/ui/button'
import { Calendar } from '@/ui/components/ui/calendar'
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@/ui/components/ui/popover'
import { cn } from '@/ui/lib/utils'
import { format } from 'date-fns'
import { Calendar as CalendarIcon } from 'lucide-react'

import { TimePicker } from './time-picker'

interface DateTimePickerProps {
  date: Date | undefined
  setDate: (date: Date | undefined) => void
}

export const DateTimePicker = (props: DateTimePickerProps) => {
  const dateObj = props.date ? new Date(props.date) : new Date()

  return (
    <Popover>
      <PopoverTrigger asChild>
        <Button
          variant={'outline'}
          className={cn(
            'w-full justify-start text-left font-normal',
            !props.date && 'text-muted-foreground'
          )}
          onClick={() => {
            console.log('on click')
          }}
        >
          <CalendarIcon className="mr-2 h-4 w-4" />
          {props.date ? (
            format(dateObj, 'PPP HH:mm:ss')
          ) : (
            <span>Pick a date</span>
          )}
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-auto p-0">
        <Calendar
          mode="single"
          selected={dateObj}
          onSelect={props.setDate}
          initialFocus
        />
        <div className="border-t border-border p-3">
          <TimePicker setDate={props.setDate} date={dateObj} />
        </div>
      </PopoverContent>
    </Popover>
  )
}

'use client'

import React, { forwardRef } from 'react'
import { cn } from '@/lib/utils'
import { Button } from '@/ui/components/ui/button'
import { Calendar } from '@/ui/components/ui/calendar'
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@/ui/components/ui/popover'
import { format } from 'date-fns'
import { Calendar as CalendarIcon } from 'lucide-react'

export const DatePicker = forwardRef<
  HTMLDivElement,
  {
    date?: Date
    setDate: (date?: Date) => void
  }
>(function DatePickerCmp({ date, setDate }, ref) {
  // const [isOpen, setIsOpen] = React.useState(false)
  console.log({ date, formattedDate: format(date || new Date(), 'PPP') })
  return (
    // <Popover open={isOpen}>
    <Popover modal={true}>
      <PopoverTrigger asChild>
        <Button
          variant={'outline'}
          data-vaul-no-drag
          // onClick={(e) => {
          //   // console.log('onClick')
          //   // e.stopPropagation()
          //   setIsOpen((prev) => !prev)
          // }}
          className={cn(
            'w-full justify-start text-left font-normal',
            !date && 'text-muted-foreground'
          )}
          // onPointerDown={(e) => {
          //   console.log('onpointerdown')
          //   e.stopPropagation()
          // }}
        >
          <CalendarIcon className="mr-2 h-4 w-4" />
          {date ? format(date, 'PPP') : <span>Pick a date</span>}
        </Button>
      </PopoverTrigger>
      <PopoverContent
        className="w-auto p-0"
        ref={ref}
        onOpenAutoFocus={(e) => e.preventDefault()}
      >
        {/* <div className="flex w-full justify-center"> */}
        <Calendar
          mode="single"
          selected={date}
          defaultMonth={date || new Date()}
          // onSelect={(d) => {
          //   console.log('select', d)
          //   if (!!d) {
          //     setDate(d)
          //   }
          //   setIsOpen(false)
          // }}
          onSelect={(d) => {
            console.log('select', d)
            if (d) {
              // Create a new Date object set to UTC
              const utcDate = new Date(
                Date.UTC(d.getFullYear(), d.getMonth(), d.getDate())
              )
              d = utcDate
            }
            console.log('select utc', d)
            setDate(d)
          }}
          initialFocus
        />
        {/* </div> */}
      </PopoverContent>
    </Popover>
  )
})

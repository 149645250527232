'use client'

import { FormLabel } from '@/components/ui/form'
import { cn } from '@/lib/utils'
import { useTranslations } from 'next-intl'

function AutoFormLabel({
  label,
  isRequired,
  className,
}: {
  label: string
  isRequired: boolean
  className?: string
}) {
  const t = useTranslations('form')
  const translatedLabel = label.startsWith('FORM_')
    ? t(label.replace('FORM_', '') as any)
    : label
  return (
    <>
      <FormLabel className={cn(className)}>
        {translatedLabel}
        {isRequired && <span className="text-destructive"> *</span>}
      </FormLabel>
    </>
  )
}

export default AutoFormLabel

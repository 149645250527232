'use client'

import * as React from 'react'
import { Badge } from '@/ui/components/ui/badge'
import { Command, CommandGroup, CommandItem } from '@/ui/components/ui/command'
import { Command as CommandPrimitive } from 'cmdk'
import { Tally5Icon, X, XSquareIcon } from 'lucide-react'

import { Button } from '../../ui/button'
import { ScrollArea } from '../../ui/scroll-area'

export const FancyMultiSelect = (props: {
  options: { label: string; value: string }[]
  placeHolder: string
  selected: string[]
  onSetSelected: (values: string[]) => void
}) => {
  const inputRef = React.useRef<HTMLInputElement>(null)
  const [open, setOpen] = React.useState(false)
  const [inputValue, setInputValue] = React.useState('')

  const handleUnselect = React.useCallback(
    (unselectedOptionValue: string) => {
      props.onSetSelected(
        props.selected.filter((s) => s !== unselectedOptionValue)
      )
    },
    [props.selected]
  )

  const handleKeyDown = React.useCallback(
    (e: React.KeyboardEvent<HTMLDivElement>) => {
      const input = inputRef.current
      if (input) {
        if (e.key === 'Delete' || e.key === 'Backspace') {
          if (input.value === '') {
            const newSelected = [...props.selected]
            newSelected.pop()
            props.onSetSelected(newSelected)
          }
        }
        // This is not a default behaviour of the <input /> field
        if (e.key === 'Escape') {
          input.blur()
        }
      }
    },
    []
  )

  const selectables = props.options.filter(
    (o) => !props.selected.includes(o.value)
  )

  return (
    <Command onKeyDown={handleKeyDown} className="bg-transparent">
      <div className="group rounded-md border border-input px-3 py-2 text-sm ring-offset-background focus-within:ring-2 focus-within:ring-ring focus-within:ring-offset-2">
        <div className="flex flex-wrap gap-1">
          {props.selected.map((option) => {
            const selectedOption = props.options.find(
              (o) => o.value === option
            )!
            return (
              <Badge key={selectedOption.label} variant="secondary">
                {selectedOption.label}
                <button
                  className="ml-1 rounded-full outline-none ring-offset-background focus:ring-2 focus:ring-ring focus:ring-offset-2"
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      handleUnselect(option)
                    }
                  }}
                  onMouseDown={(e) => {
                    e.preventDefault()
                    e.stopPropagation()
                  }}
                  onClick={() => handleUnselect(option)}
                >
                  <X className="h-3 w-3 text-muted-foreground hover:text-foreground" />
                </button>
              </Badge>
            )
          })}
          {/* Avoid having the "Search" Icon */}
          <CommandPrimitive.Input
            ref={inputRef}
            value={inputValue}
            onValueChange={setInputValue}
            onBlur={() => setOpen(false)}
            onFocus={() => setOpen(true)}
            placeholder={props.placeHolder}
            className="ml-2 flex-1 bg-transparent outline-none placeholder:text-muted-foreground"
          />
          <Button
            variant={'outline'}
            type="button"
            onClick={() => {
              props.onSetSelected(props.options.map((o) => o.value))
            }}
          >
            <Tally5Icon className="h-4 w-4" />
          </Button>
          <Button
            variant={'outline'}
            type="button"
            onClick={() => {
              props.onSetSelected([])
            }}
          >
            <XSquareIcon className="h-4 w-4" />
          </Button>
        </div>
      </div>
      {open && selectables.length > 0 ? (
        <div className="relative mt-2 h-[200px] overflow-scroll">
          <div className="absolute top-0 z-10 w-full rounded-md border bg-popover text-popover-foreground shadow-md outline-none animate-in">
            <CommandGroup className="h-full overflow-auto">
              {selectables.map((option) => {
                return (
                  <CommandItem
                    key={option.value}
                    onMouseDown={(e) => {
                      e.preventDefault()
                      e.stopPropagation()
                    }}
                    onSelect={(value) => {
                      setInputValue('')
                      props.onSetSelected([...props.selected, option.value])
                    }}
                    className={'cursor-pointer'}
                  >
                    {option.label}
                  </CommandItem>
                )
              })}
            </CommandGroup>
          </div>
        </div>
      ) : null}
    </Command>
  )
}

'use client'

import * as React from 'react'
import Link from 'next/link'
import { useRouter } from 'next/navigation'
import {
  Command,
  CommandInput,
  CommandItem,
  CommandList,
} from '@/components/ui/command'
import { Button } from '@/ui/components/ui/button'
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@/ui/components/ui/popover'
import { cn } from '@/ui/lib/utils'
import { CaretSortIcon, CheckIcon } from '@radix-ui/react-icons'

import { LabelValueOptionalUrl } from './common-types'

type PopoverTriggerProps = React.ComponentPropsWithoutRef<typeof PopoverTrigger>

interface SearchSelectBoxProps extends PopoverTriggerProps {
  onSelectedOptionChanged?: (id: string | undefined) => void
  allOptions: LabelValueOptionalUrl[]
  selectedOption?: string | null
}

export const SearchSelectBox = (props: SearchSelectBoxProps) => {
  const router = useRouter()
  const [open, setOpen] = React.useState(false)
  const selectedOption = props.allOptions.find(
    (o) => o.value === props.selectedOption
  )
  return (
    <Popover open={open} onOpenChange={setOpen} modal={true}>
      <PopoverTrigger asChild>
        <Button
          variant="outline"
          role="combobox"
          aria-expanded={open}
          aria-label="Select a team"
          className={cn('w-full justify-between', props.className)}
        >
          {/* {props.selectedOption && (
            <Avatar className="mr-2 h-5 w-5">
              <AvatarFallback>
                {getInitialsFromSlug(props.selectedOption.label)}
              </AvatarFallback>
            </Avatar>
          )} */}
          {selectedOption?.label ?? 'Please select'}
          <CaretSortIcon className="ml-auto h-4 w-4 shrink-0 opacity-50" />
        </Button>
      </PopoverTrigger>
      {selectedOption?.url && (
        <Link href={selectedOption.url}>
          <Button variant="link" className="ml-2">
            View
          </Button>
        </Link>
      )}
      <PopoverContent className="w-[400px] p-0">
        <Command>
          <CommandList>
            <CommandInput placeholder="Search..." />
            {props.allOptions.map((option) => (
              <CommandItem
                key={option.value}
                onSelect={() => {
                  setOpen(false)
                  props.onSelectedOptionChanged?.(option.value)
                  if (option.url) {
                    router.push(option.url)
                  }
                }}
                className="text-sm"
              >
                {option.label}
                <CheckIcon
                  className={cn(
                    'ml-auto h-4 w-4',
                    selectedOption?.value === option.value
                      ? 'opacity-100'
                      : 'opacity-0'
                  )}
                />
              </CommandItem>
            ))}
          </CommandList>
        </Command>
      </PopoverContent>
    </Popover>
  )
}

'use client'

import React from 'react'
import { Form } from '@/components/ui/form'
import { cn } from '@/ui/lib/utils'
import { zodResolver } from '@hookform/resolvers/zod'
import { DefaultValues, useForm } from 'react-hook-form'
import { z } from 'zod'

import { Button, ButtonProps } from '../button'
import AutoFormObject from './fields/object'
import { Dependency, FieldConfig } from './types'
import type { ZodObjectOrWrapped } from './utils'
import { getDefaultValues, getObjectFormSchema } from './utils'

export function AutoFormSubmit({
  children,
  ...buttonProps
}: { children?: React.ReactNode } & ButtonProps) {
  buttonProps.disabled = buttonProps.isLoading ? true : buttonProps.disabled
  return (
    <Button type="submit" {...buttonProps}>
      {children ?? 'Submit'}
    </Button>
  )
}

function AutoForm<SchemaType extends ZodObjectOrWrapped>({
  formSchema,
  values: valuesProp,
  onValuesChange: onValuesChangeProp,
  onParsedValuesChange,
  onSubmit: onSubmitProp,
  fieldConfig,
  children,
  className,
  dependencies,
  action,
}: {
  formSchema: SchemaType
  values?: Partial<z.infer<SchemaType>>
  onValuesChange?: (values: Partial<z.infer<SchemaType>>) => void
  onParsedValuesChange?: (values: Partial<z.infer<SchemaType>>) => void
  onSubmit?: (values: z.infer<SchemaType>) => void
  fieldConfig?: FieldConfig<z.infer<SchemaType>>
  children?: React.ReactNode
  className?: string
  dependencies?: Dependency<z.infer<SchemaType>>[]
  action?: (formData: FormData) => void
  dontAddScroll?: boolean
}) {
  const objectFormSchema = getObjectFormSchema(formSchema)
  const defaultValues: DefaultValues<z.infer<typeof objectFormSchema>> | null =
    getDefaultValues(objectFormSchema, fieldConfig)

  const form = useForm<z.infer<typeof objectFormSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: defaultValues ?? undefined,
    values: valuesProp,
  })

  function onSubmit(values: z.infer<typeof formSchema>) {
    const parsedValues = formSchema.safeParse(values)
    if (parsedValues.success) {
      onSubmitProp?.(parsedValues.data)
    }
  }

  const values = form.watch()
  const valuesString = JSON.stringify(values)

  React.useEffect(() => {
    // console.log('on values change in effect', values)
    // const values = form.getValues()
    onValuesChangeProp?.(values)
    const parsedValues = formSchema.safeParse(values)
    if (parsedValues.success) {
      onParsedValuesChange?.(parsedValues.data)
    }
  }, [valuesString])

  return (
    <Form {...form}>
      <form
        action={action}
        onSubmit={async (e) => {
          await form.handleSubmit(onSubmit)(e)
        }}
        // onChange={() => {
        //   console.log('on change values in form', form.getValues())
        //   // const values = form.getValues()
        //   // onValuesChangeProp?.(values)
        //   // const parsedValues = formSchema.safeParse(values)
        //   // if (parsedValues.success) {
        //   //   onParsedValuesChange?.(parsedValues.data)
        //   // }
        // }}
        className={cn(
          'flex flex-col justify-end space-y-4',
          // !dontAddScroll && 'max-h-[calc(100vh-200px)] overflow-y-auto',
          className
        )}
      >
        <AutoFormObject
          schema={objectFormSchema}
          form={form}
          dependencies={dependencies}
          fieldConfig={fieldConfig}
        />
        {children}
      </form>
    </Form>
  )
}

export default AutoForm

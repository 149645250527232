import { FormMessage } from '@/components/ui/form'
import { AlertTriangle } from 'lucide-react'

import { Alert, AlertDescription, AlertTitle } from '../../alert'
import AutoFormLabel from '../common/label'
import AutoFormTooltip from '../common/tooltip'
import { AutoFormInputComponentProps } from '../types'

export default function AutoFormWarning({
  label,
  isRequired,
  fieldConfigItem,
  fieldProps,
}: AutoFormInputComponentProps) {
  const { showLabel: _showLabel, defaultValue: __defaultValue } = fieldProps
  const showLabel = _showLabel === undefined ? true : _showLabel

  return (
    <div className="flex flex-row items-center space-x-2">
      <Alert className="rounded-lg border border-orange-200 bg-amber-50">
        <AlertTriangle className="h-4 w-4" />
        <AlertTitle>
          {showLabel && <AutoFormLabel label={label} isRequired={isRequired} />}
        </AlertTitle>
        <AlertDescription>
          <AutoFormTooltip fieldConfigItem={fieldConfigItem} />
        </AlertDescription>
        <FormMessage />
      </Alert>
    </div>
  )
}
